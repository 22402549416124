@import url("../node_modules/@fortawesome/fontawesome-free/css/all.min.css");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* so the page appear full of body */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  letter-spacing: 0.1px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
