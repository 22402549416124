#root {
  /* so the page appear full of body */
  width: 100vw;
  height: 100%;
  position: relative;
  /* need this position relative otherwise will overflow */
}

.clickable {
  cursor: pointer;
}
